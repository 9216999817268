<template>
  <vuestic-widget>
    <div class="container">
      <div class="row justify-content-between">
        <div class="pesquisa">
          <el-input
            style="margin-bottom: 10px; width: 150px;"
            v-model="searchIndicador"
            placeholder="Pesquisar"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-search"
            ></i>
          </el-input>
        </div>
        <button
          class="btn btn-primary btn-micro"
          @click="selecionarIndicado(indicador, 'modalCadastrarIndicador')"
        >Cadastrar indicador pai</button>
      </div>
    </div>
    <hr class="mt-2 mb-2">
    <div class="custom-tree-container">
      <div class="block">
        <el-tree
          v-loading="loading"
          :data="indicadores"
          node-key="id"
          :props="defaultProps"
          :expand-on-click-node="true"
          ref="tree"
          :filter-node-method="filterIndicador"
        >
          <span
            class="custom-tree-node line"
            slot-scope="{ node, data }"
          >
            <span>{{ data.descricao }}</span>
            <span class="icons">
              <i
                class="el-icon-circle-plus"
                @click="selecionarIndicado(data, 'modalCadastrarIndicador')"
                circle
              >
              </i>
              <i
                class="el-icon-delete danger"
                @click="selecionarIndicado(data, 'modalDeletarIndicador')"
                circle
              >
              </i>
            </span>
          </span>
        </el-tree>
      </div>
    </div>
    <!-- modal para cadastrar indicador -->
    <vuestic-modal
      ref="modalCadastrarIndicador"
      v-on:ok="prepararParaCadastrar()"
    >
      <div slot="title">Cadastrar Indicador</div>
      <div>
        <fieldset>
          <div class="form-group">
            <div class="input-group">
              <p>Nome</p>
              <input
                v-model="indicador.descricao"
                id="simple-input"
                required
              >
              <label
                class="control-label"
                for="simple-input"
              ></label>
            </div>
          </div>
        </fieldset>
      </div>
    </vuestic-modal>
    <vuestic-modal
      ref="modalDeletarIndicador"
      v-on:ok="deletarIndicador(indicadorSelecionado.id)"
    >
      <div slot="title">Excluir</div>
      <div>Você deseja excluir o indicador <b>{{indicadorSelecionado.descricao || ''}}</b>?</div>
    </vuestic-modal>
  </vuestic-widget>
</template>
<script>

export default {
  data() {
    return {
      searchIndicador: '',
      loading: false,
      defaultProps: {
        children: 'filhos',
      },
      indicadores: [],
      indicadorSelecionado: {},
      indicador: {
        descricao: '',
        id_superior: null,
      },
    };
  },
  created() {
    this.getIndicadores();
  },
  watch: {
    searchIndicador(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    filterIndicador(value, data) {
      if (!value) return true;
      return data.descricao.toUpperCase().indexOf(value.toUpperCase()) !== -1;
    },
    getIndicadores() {
      this.loading = true;
      this.$axios.get('/api/indicador.json').then((res) => {
        this.indicadores = (res.data);
        this.loading = false;
      });
    },
    selecionarIndicado(data, modal) {
      this.indicadorSelecionado = { ...data };
      this.$refs[modal].open();
    },
    prepararParaCadastrar() {
      this.indicador.id_superior = this.indicadorSelecionado.id || null;
      this.cadastarIndicador();
    },
    cadastarIndicador() {
      this.$axios.post('/api/indicador/', this.indicador).then(() => {
        this.message('success', 'Indicador Cadastrardo com Sucesso!!!');
        this.limparCampos();
        this.getIndicadores();
      }).catch(() => {
        this.message('error', 'Não foi possivel cadastrar o Indicador!!!');
        this.getIndicadores();
      });
    },
    deletarIndicador(id) {
      this.$axios.delete(`/api/indicador/${id}/`).then(() => {
        this.message('success', 'Indicador Excluido com Sucesso!!!');
        this.limparCampos();
        this.getIndicadores();
      }).catch(() => {
        this.message('error', 'Não foi possivel excluir o Indicador!!!');
        this.getIndicadores();
      });
    },
    limparCampos() {
      this.indicador = {
        descricao: '',
        id_superior: null,
      };
      this.indicadorSelecionado = {};
    },
    message(tipo, msg) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type: tipo,
        title: msg,
        showConfirmButton: false,
        timer: 3500,
      });
    },
  },
};
</script>

<style>
.custom-tree-node {
	flex: 1;
	display: flex;
	align-items: center;
	font-size: 1.2rem;
}

.el-icon-circle-plus {
	margin-right: 5px;
	margin-left: 1rem;
}

.line:hover .icons {
	display: block !important;
}
.line .icons {
	display: none !important;
}
</style>
